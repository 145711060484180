import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import MeetingListingCard from "../components/Card/meetinglistingcard"
import SEOHeader from "../components/seo-header"
import { graphql, Link, navigate } from "gatsby"
import BlogCardCategory from "../components/blog/blog-card-category"
import { MeetingContent } from "../components/pagecontent/meetingcontent"
const MeetingSpacesLocalityAll = props => {
  const { city } = props.pageContext
  const { cityslug } = props.pageContext
  const { locality } = props.pageContext
  const params = new URLSearchParams(props.location.search)
  const operatorName = params.get("operatorName") // bar
  const purposeTag = params.get("purpose") // bar
  const priceTag = params.get("priceOption")
  const localityTag = params.get("locality")
  const seat = params.get("seat")
  const minvalue = params.get("minvalue")
  const maxvalue = params.get("maxvalue")
  const [filtersmodalView, setfiltersmodalView] = useState(false)
  const { slug } = props.pageContext
  const [spaceFilter, setspaceFilter] = useState("All")
  const [spaceSize, setspaceSize] = useState([])
  const [priceRange, setpriceRange] = useState(50)
  const [filterStyle, setfilterStyle] = useState("Popular")
  const [ProjectFilter, setProjectFilter] = useState("false")
  const [CoffeeTeaFilter, setCoffeeTeaFilter] = useState("false")
  const [LessFiveFilter, setLessFiveFilter] = useState(false)
  const [SixtoFifteenFilter, setSixtoFifteenFilter] = useState(false)
  const [MorethanFiftyFilter, setMorethanFiftyFilter] = useState(false)
  const [parkingCheck, setparkingCheck] = useState(false)
  const [projectorCheck, setprojectorCheck] = useState(false)
  const [whiteboardCheck, setwhiteboardCheck] = useState(false)
  const [cafeteriaCheck, setcafeteriaCheck] = useState(false)
  const [powerBackup, setpowerBackup] = useState(false)
  const [WiFiBackupCheck, setWiFiBackupCheck] = useState(false)
  const [PowerBackupCheck, setPowerBackupCheck] = useState(false)
  const [MetroNearbyCheck, setMetroNearbyCheck] = useState(false)
  const [CoffeeTea, setCoffeeTea] = useState(false)
  const [mobileFilterSecondary, setmobileFilterSecondary] = useState(false)
  const [mobileFilter, setmobileFilter] = useState(false)
  const [AnySpaces, setAnySpaces] = useState(false)
  const [OnetoOneMeetings, setOnetoOneMeetings] = useState(false)
  const [TeamMeetings, setTeamMeetings] = useState(false)
  const [BoardMeetingsPresentations, setBoardMeetingsPresentations] = useState(
    false
  )
  const [Interviews, setInterviews] = useState(false)
  const [WorkshopsEvents, setWorkshopsEvents] = useState(false)
  const [Training, setTraining] = useState(false)
  const [operationTime, setoperationTime] = useState("")
  const [operationFilter, setoperationFilter] = useState(" ")
  const [sundayOperation, setsundayOperation] = useState(false)
  const [saturdayOperation, setsaturdayOperation] = useState(false)
  const [budgetFilter, setbudgetFilter] = useState(false)
  const [businessFilter, setbusinessFilter] = useState(false)
  const [premiumFilter, setpremiumFilter] = useState(false)
  const [filterType, setFilterType] = useState("")
  const [miniValue, setminiValue] = useState(minvalue ? Number(minvalue) : 0)
  const [maxiValue, setmaxiValue] = useState(
    maxvalue ? Number(maxvalue) : 20000
  )
  const [seatCount, setSeatCount] = useState(seat ? Number(seat) : 0)
  const [optionValue, setoptionValue] = useState(
    priceTag ? priceTag : "option_0"
  )
  const [localityFilter, setlocalityFilter] = useState("All")
  const [purposeFilter, setpurposeFilter] = useState("AnySpaces")
  const [priceFilter, setpriceFilter] = useState("")
  const [instantBooking, setInstantBooking] = useState(false)
  const filter = {
    keyword: city,
  }
  const [scrollState, setscrollState] = useState("")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState(" ")
    } else if (window.scrollY !== 0) {
      setscrollState("active")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  useEffect(() => {
    if (cityslug) {
      localStorage.setItem("slug", cityslug)
    }
    if (localStorage.getItem("locality")) {
      localStorage.setItem("slug", cityslug)
      setlocalityFilter(localStorage.getItem("locality"))
    } else {
      setlocalityFilter("All")
    }
  }, [])

  const operationTimeChange = e => {
    setoperationTime(e.target.value)
    if (e.target.value == "Open till 8 pm") {
      setoperationFilter("Till 8PM")
    } else if (e.target.value == "Open till 10 pm") {
      setoperationFilter("Till 10PM")
    } else if (e.target.value == "24 x 7") {
      setoperationFilter("Twenty Four Hours")
    }
  }

  const parkingCheckOnChange = () => {
    setparkingCheck(!parkingCheck)
  }
  const projectorCheckOnChange = () => {
    setprojectorCheck(!projectorCheck)
  }
  const whiteboardCheckOnChange = () => {
    setwhiteboardCheck(!whiteboardCheck)
  }
  const cafeteriaCheckOnChange = () => {
    setcafeteriaCheck(!cafeteriaCheck)
  }

  const MetroNearbyCheckOnChange = () => {
    setMetroNearbyCheck(!MetroNearbyCheck)
  }
  const CoffeeTeaonChange = () => {
    setCoffeeTea(!CoffeeTea)
  }

  const OnetoOneMeetingsChange = () => {
    setOnetoOneMeetings(!OnetoOneMeetings)
  }
  const TeamMeetingsChange = () => {
    setTeamMeetings(!TeamMeetings)
  }
  const BoardMeetingsPresentationsChange = () => {
    setBoardMeetingsPresentations(!BoardMeetingsPresentations)
  }
  const InterviewsChange = () => {
    setInterviews(!Interviews)
  }
  const TrainingChange = () => {
    setTraining(!Training)
  }
  const WorkshopsEventsChange = () => {
    setWorkshopsEvents(!WorkshopsEvents)
  }
  const saturdayOperationChange = () => {
    setsaturdayOperation(!saturdayOperation)
  }
  const sundayOperationChange = () => {
    setsundayOperation(!sundayOperation)
  }
  function CoffeeTeaCheck() {
    setCoffeeTeaFilter(!CoffeeTeaFilter)
  }

  // useEffect(() => {
  //   // Check the value of priceTag

  //   if (priceTag === "option_0") {
  //     setoptionValue("option_0")
  //     setminiValue(0)
  //     setmaxiValue(20000)
  //   } else if (priceTag === "option_1") {
  //     setoptionValue("option_1")
  //     setminiValue(0)
  //     setmaxiValue(seatCount != 0 ? seatCount * 125 : 500)
  //   } else if (priceTag === "option_2") {
  //     setoptionValue("option_2")
  //     setminiValue(seatCount != 0 ? seatCount * 125 : 500)
  //     setmaxiValue(seatCount != 0 ? seatCount * 150 : 600)
  //   } else if (priceTag === "option_3") {
  //     setoptionValue("option_3")
  //     setminiValue(seatCount != 0 ? seatCount * 150 : 600)
  //     setmaxiValue(seatCount != 0 ? seatCount * 200 : 800)
  //   } else if (priceTag === "option_4") {
  //     setoptionValue("option_4")
  //     setminiValue(seatCount != 0 ? seatCount * 200 : 800)
  //     setmaxiValue(20000)
  //   } else {
  //     setoptionValue("option_0")
  //     setminiValue(0)
  //     setmaxiValue(20000)
  //     console.log("priceTag", priceTag)
  //     console.log("priceTag", optionValue, miniValue, maxiValue)
  //   }
  // }, [priceTag, seatCount])

  // useEffect(() => {
  //   if (optionValue === "option_0") {
  //     setminiValue(0)
  //     setmaxiValue(20000)
  //   } else if (optionValue === "option_1") {
  //     setminiValue(0)
  //     setmaxiValue(seatCount != 0 ? seatCount * 125 : 500)
  //   } else if (optionValue === "option_2") {
  //     setminiValue(seatCount != 0 ? seatCount * 125 : 500)
  //     setmaxiValue(seatCount != 0 ? seatCount * 150 : 600)
  //   } else if (optionValue === "option_3") {
  //     setminiValue(seatCount != 0 ? seatCount * 150 : 600)
  //     setmaxiValue(seatCount != 0 ? seatCount * 200 : 800)
  //   } else if (optionValue === "option_4") {
  //     setminiValue(seatCount != 0 ? seatCount * 200 : 800)
  //     setmaxiValue(20000)
  //   } else {
  //     setminiValue(0)
  //     setmaxiValue(20000)
  //   }
  // }, [optionValue, seatCount])

  // My COde
  function InstantBookingCheck() {
    if (instantBooking === "true") {
      setInstantBooking(!instantBooking)
    } else {
      setInstantBooking("true")
      setspaceFilter("Instant Booking")
    }
  }
  useEffect(() => {
    if (params.get("purpose")) {
      setpurposeFilter(params.get("purpose"))
    } else {
      setpurposeFilter("AnySpaces")
    }

    // switch (params.get("purpose")) {
    //   case "AnySpaces":
    //     setAnySpaces(true)
    //     break
    //   case "Team Meeting":
    //     setTeamMeetings(true)
    //     break
    //   case "Training":
    //     setTraining(true)
    //     break
    //   case "Board Meetings":
    //     setBoardMeetingsPresentations(true)
    //     break
    //   case "Interviews":
    //     setInterviews(true)
    //     break
    //   case null:
    //     setAnySpaces(true)
    //     return " "
    //   default:
    //     setAnySpaces(true)
    //     return " "
    // }
  }, [])

  // // useEffect(() => {
  // //   if (seat) {
  // //     setSeatCount(seat)
  // //   } else {
  // //     setSeatCount(0)
  // //   }
  // // }, [])

  // useEffect(() => {
  //   let newOptionValue = "option_0"
  //   let newMiniValue = 0
  //   let newMaxiValue = 20000

  //   switch (params.get("priceOption")) {
  //     case "option_0":
  //       newOptionValue = "option_0"
  //       newMiniValue = 0
  //       newMaxiValue = 20000
  //       break
  //     case "option_1":
  //       newOptionValue = "option_1"
  //       newMiniValue = 0
  //       newMaxiValue = seatCount !== 0 ? seatCount * 125 : 500
  //       break
  //     case "option_2":
  //       newOptionValue = "option_2"
  //       newMiniValue = seatCount !== 0 ? seatCount * 125 : 500
  //       newMaxiValue = seatCount !== 0 ? seatCount * 150 : 600
  //       break
  //     case "option_3":
  //       newOptionValue = "option_3"
  //       newMiniValue = seatCount !== 0 ? seatCount * 150 : 600
  //       newMaxiValue = seatCount !== 0 ? seatCount * 200 : 800
  //       break
  //     case "option_4":
  //       newOptionValue = "option_4"
  //       newMiniValue = seatCount !== 0 ? seatCount * 200 : 800
  //       newMaxiValue = 20000
  //       break
  //     default:
  //       break
  //   }

  //   setoptionValue(newOptionValue)
  //   setminiValue(newMiniValue)
  //   setmaxiValue(newMaxiValue)
  // }, [priceTag, seatCount])

  useEffect(() => {
    // Update body overflow based on filtersmodalView state
    if (filtersmodalView) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "scroll"
    }
  }, [filtersmodalView])

  // useEffect(() => {
  //   if (params.get("operatorName") || params.get("priceOption")) {
  //     setfiltersmodalView(false)
  //   } else {
  //     setfiltersmodalView(true)
  //   }
  // }, [])

  var projectquery,
    coffeeteaquery,
    fiveseatquery,
    sixtofifteenquery,
    morethanfiftyquery,
    fivefiftyquery,
    instantbookingquery,
    tvquery = " "
  if (instantBooking === "true") {
    instantbookingquery = "Instant Booking"
  } else {
    instantbookingquery = " "
  }
  if (ProjectFilter === "true") {
    projectquery = "Projector"
    tvquery = "Television"
  } else {
    projectquery = " "
    tvquery = " "
  }
  if (CoffeeTeaFilter === true) {
    coffeeteaquery = "Coffee/Tea"
  } else {
    coffeeteaquery = " "
  }
  if (LessFiveFilter) {
    fiveseatquery = "Less Than Five"
  } else {
    fiveseatquery = " "
  }
  if (SixtoFifteenFilter) {
    sixtofifteenquery = "Five to Fifteen"
  } else {
    sixtofifteenquery = " "
  }
  if (MorethanFiftyFilter) {
    morethanfiftyquery = "More than Fifteen"
  } else {
    morethanfiftyquery = " "
  }
  if (LessFiveFilter && SixtoFifteenFilter) {
    fivefiftyquery = "moption a"
  }
  if (LessFiveFilter && MorethanFiftyFilter) {
    fivefiftyquery = "moption b"
  }
  if (SixtoFifteenFilter && MorethanFiftyFilter) {
    fivefiftyquery = "moption c"
  }
  if (
    LessFiveFilter === "true" &&
    SixtoFifteenFilter === "true" &&
    MorethanFiftyFilter === "true"
  ) {
    fivefiftyquery = "moption d"
  }
  const lists = props.data.allListings.edges
  const LocalityList = props.data.allLocalities.edges
  const LocalityListSelect = LocalityList.map(local => {
    const localitynew = lists.filter(
      list => list.node.spaceAddress === local.node.localityName
    )

    if (localitynew.length > 0) {
      return (
        <option value={local.node.localityName}>
          {local.node.localityName} ({localitynew.length}{" "}
          {localitynew.length > 1 ? "Options" : "Option"})
        </option>
      )
    }
  })

  function seatChange(event) {
    setSeatCount(event.target.value)

    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?locality=" +
        localityFilter +
        "&purpose=" +
        purposeFilter +
        "&priceOption=" +
        priceFilter +
        "&seat=" +
        event.target.value +
        "&minSeat=" +
        miniValue +
        "&maxSeat=" +
        maxiValue

      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
  const localityChange = e => {
    setlocalityFilter(e.target.value)
    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?locality=" +
        e.target.value +
        "&purpose=" +
        purposeFilter +
        "&priceOption=" +
        priceFilter +
        "&seat=" +
        seatCount +
        "&minvalue=" +
        miniValue +
        "&maxvalue=" +
        maxiValue

      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
  const purposeChange = purpose => {
    setpurposeFilter(purpose)
    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?locality=" +
        localityFilter +
        "&purpose=" +
        purpose +
        "&priceOption=" +
        priceFilter +
        "&seat=" +
        seatCount +
        "&minvalue=" +
        miniValue +
        "&maxvalue=" +
        maxiValue

      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
  const priceFilterChange = (priceOption, miniValue, maxiValue) => {
    setpriceFilter(priceOption)
    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?locality=" +
        localityFilter +
        "&purpose=" +
        purposeFilter +
        "&priceOption=" +
        priceOption +
        "&seat=" +
        seatCount +
        "&minvalue=" +
        miniValue +
        "&maxvalue=" +
        maxiValue

      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
  const listscount = props.data.city.edges
  const count = props.data.counter.totalCount
  const filterdata = props.data.counter.edges
  const OfficeFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardMeetingSpace").length)
    })

    return (
      <div className="officefiltercontainer">
        <ul className="OfficeFilter scrollmenu" id="officefiltercontainer">
          <li>
            <p className={"LocalityBoxMeeting "}>
              <select onChange={localityChange} value={localityFilter}>
                <option value="All">All Localities ({count} Options)</option>
                {LocalityListSelect}
              </select>
            </p>
          </li>

          <li>
            <div
              className={
                filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Popular")
              }}
            >
              <p>Popular</p>
            </div>
          </li>
          <li>
            <div
              className={
                filterStyle === "User Rating"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("User Rating")
              }}
            >
              <p>User Rating</p>
            </div>
          </li>
          <li>
            <div
              className={
                filterStyle === "PriceLow"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceLow")
              }}
            >
              <p>Price - Lowest</p>
            </div>
          </li>
        </ul>
      </div>
    )
  }

  const MeetingSpaceFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardPadding").length)
    })

    return (
      <div>
        <div className="meetingSpaceFilter">
          <div>
            {count - spaceSize <= 1 ? (
              ""
            ) : (
              <a
                style={{
                  color: "#4f9fc8",
                  padding: "2px 5px",
                  float: "right",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                className="DesktopOnly"
                onClick={() => {
                  if (window.history) {
                    var newurl =
                      window.location.protocol +
                      "//" +
                      window.location.host +
                      window.location.pathname

                    window.history.pushState({ path: newurl }, "", newurl)
                    window.location.reload(true)
                  }
                }}
              >
                CLEAR
              </a>
            )}
            <span style={{ fontSize: "18px" }}>Filters</span>{" "}
          </div>{" "}
          <br />
          <strong>Quick Filter</strong>
          <br />
          <div className="quickFilter">
            <li>
              <a
                className={"CheckBox " + instantBooking}
                onClick={InstantBookingCheck}
              >
                Instantly Book{" "}
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </a>
            </li>
          </div>
          <br />
          <strong>Size</strong>
          <div>
            <select
              onChange={seatChange}
              value={seatCount}
              className="form-control"
            >
              <option value={0}>Any</option>
              <option value={4}>4 Seats</option>
              <option value={6}>6 Seats</option>
              <option value={8}>8 Seats</option>
              <option value={10}>10 Seats</option>
              <option value={12}>12 Seats</option>
              <option value={15}>15 Seats</option>
              <option value={20}>&gt; 20 Seats</option>
            </select>
          </div>
          <br />
          <strong>Price / hr Range</strong>
          <br></br>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="radio"
                name="priceFilter"
                checked={optionValue === "option_0"}
                value="option_1"
                onChange={value => {
                  setoptionValue("option_0")
                  setminiValue(0)
                  setmaxiValue(20000)
                  priceFilterChange("option_0", 0, 20000)
                }}
              ></input>
              Any
            </li>
            <li>
              <input
                type="radio"
                name="priceFilter"
                checked={optionValue === "option_1"}
                value="option_1"
                onChange={value => {
                  setoptionValue("option_1")
                  setminiValue(0)
                  setmaxiValue(seatCount != 0 ? seatCount * 125 : 500)
                  priceFilterChange(
                    "option_1",
                    0,
                    seatCount != 0 ? seatCount * 125 : 500
                  )
                }}
              ></input>
              Less than ₹ {seatCount != 0 ? seatCount * 125 : 500}{" "}
            </li>
            <li>
              <input
                type="radio"
                name="priceFilter"
                checked={optionValue === "option_2"}
                value="option_2"
                onChange={value => {
                  setoptionValue("option_2")
                  setminiValue(seatCount != 0 ? seatCount * 125 : 500)
                  setmaxiValue(seatCount != 0 ? seatCount * 150 : 600)
                  priceFilterChange(
                    "option_2",
                    seatCount != 0 ? seatCount * 125 : 500,
                    seatCount != 0 ? seatCount * 150 : 600
                  )
                }}
              ></input>
              ₹ {seatCount != 0 ? seatCount * 125 : 500} to ₹{" "}
              {seatCount != 0 ? seatCount * 150 : 600}
            </li>
            <li>
              {" "}
              <input
                type="radio"
                name="priceFilter"
                checked={optionValue === "option_3"}
                value="option_3"
                onChange={value => {
                  setoptionValue("option_3")
                  setminiValue(seatCount != 0 ? seatCount * 150 : 600)
                  setmaxiValue(seatCount != 0 ? seatCount * 200 : 800)
                  priceFilterChange(
                    "option_3",
                    seatCount != 0 ? seatCount * 150 : 600,
                    seatCount != 0 ? seatCount * 200 : 800
                  )
                }}
              ></input>
              ₹ {seatCount != 0 ? seatCount * 150 : 600} to ₹{" "}
              {seatCount != 0 ? seatCount * 200 : 800}
            </li>
            <li>
              <input
                type="radio"
                name="priceFilter"
                checked={optionValue === "option_4"}
                value="option_4"
                onChange={value => {
                  setoptionValue("option_4")
                  setminiValue(seatCount != 0 ? seatCount * 200 : 800)
                  setmaxiValue(20000)
                  priceFilterChange(
                    "option_4",
                    seatCount != 0 ? seatCount * 200 : 800,
                    20000
                  )
                }}
              ></input>
              Over ₹ {seatCount != 0 ? seatCount * 200 : 800}
            </li>
          </ul>
          <strong>Purpose</strong>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="checkbox"
                id="OnetoOneMeetings"
                name="OnetoOneMeetings"
                value="OnetoOneMeetings"
                checked={OnetoOneMeetings}
                onChange={OnetoOneMeetingsChange}
              />
              One to One Meetings
            </li>
            <li>
              <input
                type="checkbox"
                id="TeamMeetings"
                name="TeamMeetings"
                value="TeamMeetings"
                checked={TeamMeetings}
                onChange={TeamMeetingsChange}
              />
              Team Meetings
            </li>
            <li>
              <input
                type="checkbox"
                id="BoardMeetingsPresentations"
                name="BoardMeetingsPresentations"
                value="BoardMeetingsPresentations"
                checked={BoardMeetingsPresentations}
                onChange={BoardMeetingsPresentationsChange}
              />
              Board Meetings & Presentations
            </li>
            <li>
              <input
                type="checkbox"
                id="Interviews"
                name="Interviews"
                value="Interviews"
                checked={Interviews}
                onChange={InterviewsChange}
              />
              Interviews
            </li>
            <li>
              <input
                type="checkbox"
                id="WorkshopsEvents"
                name="WorkshopsEvents"
                value="WorkshopsEvents"
                checked={WorkshopsEvents}
                onChange={WorkshopsEventsChange}
              />
              Workshops & Events
            </li>
            <li>
              <input
                type="checkbox"
                id="Training"
                name="Training"
                value="Training"
                checked={Training}
                onChange={TrainingChange}
              />
              Training
            </li>
          </ul>
          <strong>Amenities</strong>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="checkbox"
                id="projector"
                name="projector"
                value="projector"
                checked={projectorCheck}
                onChange={projectorCheckOnChange}
              />
              Projector / TV{" "}
            </li>
            <li>
              <input
                type="checkbox"
                id="whiteboard"
                name="whiteboard"
                value="whiteboard"
                checked={whiteboardCheck}
                onChange={whiteboardCheckOnChange}
              />
              Whiteboard
            </li>

            <li>
              <input
                type="checkbox"
                id="teacoffee"
                name="teacoffee"
                value="teacoffee"
                checked={CoffeeTea}
                onChange={CoffeeTeaonChange}
              />
              Tea / Coffee
            </li>
            {/* <li>
            <input
              type="checkbox"
              id="wifi"
              name="wifi"
              value="wifi"
              checked={WiFiBackupCheck}
              onChange={WiFiBackupCheckOnChange}
            />
            WiFi Backup
          </li> */}
            <li>
              <input
                type="checkbox"
                id="Cafeteria"
                name="Cafeteria"
                value="Cafeteria"
                checked={cafeteriaCheck}
                onChange={cafeteriaCheckOnChange}
              />
              Pantry / Cafeteria
            </li>
          </ul>
          <strong>Transport</strong>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="checkbox"
                id="parking"
                name="parking"
                value="parking"
                checked={parkingCheck}
                onChange={parkingCheckOnChange}
              />
              Parking
            </li>
            <li>
              {" "}
              <input
                type="checkbox"
                id="MetroNearbyCheck"
                name="MetroNearbyCheck"
                value="MetroNearbyCheck"
                checked={MetroNearbyCheck}
                onChange={MetroNearbyCheckOnChange}
              />
              Metro Nearby
            </li>
          </ul>
          <strong>Operation Hours</strong>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="radio"
                name="operationTime"
                value="Open till 8 pm"
                checked={operationTime === "Open till 8 pm"}
                onChange={operationTimeChange}
              />
              Open till 8 pm
            </li>
            <li>
              <input
                type="radio"
                name="operationTime"
                value="Open till 10 pm"
                checked={operationTime === "Open till 10 pm"}
                onChange={operationTimeChange}
              />
              Open till 10 pm
            </li>
            <li>
              <input
                type="radio"
                name="operationTime"
                value="24 x 7"
                checked={operationTime === "24 x 7"}
                onChange={operationTimeChange}
              />
              24 x 7
            </li>
          </ul>
          <strong>Weekend Operation</strong>
          <ul style={{ listStyle: "none" }}>
            <li>
              <input
                type="checkbox"
                name="saturdayOperation"
                value="Open till 8 pm"
                checked={saturdayOperation}
                onChange={saturdayOperationChange}
              />
              Saturday
            </li>
            <li>
              <input
                type="checkbox"
                name="sundayOperation"
                value="Open till 8 pm"
                checked={sundayOperation}
                onChange={sundayOperationChange}
              />
              Sunday
            </li>
          </ul>
        </div>
      </div>
    )
  }

  const filterdatasorted = filterdata.sort(function (a, b) {
    return a.node.priceHr - b.node.priceHr
  })
  let lowrate
  if (filterdatasorted.length > 0) {
    lowrate = filterdatasorted[0].node.priceHr
  } else {
    lowrate = 0
  }
  if (lowrate === "") {
    lowrate = 299
  }
  const filterdataseatsorted = filterdata.sort(function (a, b) {
    return a.node.seat - b.node.seat
  })
  const filterdataseatsortedlength = filterdataseatsorted.length
  let lowseat, highseat
  if (filterdatasorted.length > 0) {
    lowseat = filterdataseatsorted[0].node.seat
    highseat = filterdataseatsorted[filterdataseatsortedlength - 1].node.seat
  } else {
    lowseat = 0
    highseat = 0
  }

  let textnew
  if (lowseat !== highseat) {
    textnew = lowseat + " Seaters to " + highseat + " Seaters"
  } else {
    textnew = lowseat + " Seaters"
  }
  useEffect(() => {
    if (lists.length <= 0) {
      navigate("/meeting-spaces/" + cityslug + "/all")
    }
  }, [])
  const title =
    "Business Meeting Rooms in " +
    locality +
    ", " +
    city +
    " | Meeting Spaces in near " +
    locality +
    ", " +
    city +
    " | GoFloaters"
  const description =
    "On-demand meeting rooms in " +
    locality +
    ", " +
    city +
    " just a click away. Discover and book business meeting rooms, boardrooms, and conference spaces that help being hybrid, productive and great for collaborations."
  const counterValue = Number(count) > 1 ? " Spaces" : " Space"
  const counterValuesmall = Number(count) > 1 ? " spaces" : " space"
  const dynamictitle =
    count +
    " Best Meeting" +
    counterValue +
    " in " +
    locality +
    ", " +
    city +
    " starting at " +
    lowrate +
    "/hr - GoFloaters"
  const dynamicdescription =
    count +
    " fully equipped meeting" +
    counterValuesmall +
    " from " +
    textnew +
    " available in " +
    locality +
    ", " +
    city +
    " at rates starting " +
    lowrate +
    "/hr. Book instantly."
  //console.log(dynamictitle, dynamicdescription)

  const changeFilter = filterText => {
    setmobileFilterSecondary(false)
    setfilterStyle(filterText)
    if (filterText === "Featured") {
      lists.sort(function (a, b) {
        if (a.node.isFeatured > b.node.isFeatured) {
          return -1
        }
      })
    }
    if (filterText === "PriceLow") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) < Number(b.node.priceHr)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) < Number(b.node.priceDay)) {
          return -1
        }
      })
    }
    if (filterText === "PriceHigh") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) > Number(b.node.priceDay)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) > Number(b.node.priceHr)) {
          return -1
        }
      })
    }
    if (filterText === "User Rating") {
      lists.sort(function (a, b) {
        if (Number(a.node.avgRating) > Number(b.node.avgRating)) {
          return -1
        }
      })
    }
    if (filterText === "Nearest") {
      lists.sort(function (a, b) {
        if (Number(a.node.pincode) > Number(b.node.pincode)) {
          return -1
        }
      })
    }
    if (filterText === "Popular") {
      lists.sort(function (a, b) {
        if (Number(a.node.spaceRating) > Number(b.node.spaceRating)) {
          return -1
        }
      })
    }
  }
  lists.sort(function (a, b) {
    if (Number(a.node.online) > Number(b.node.online)) {
      return -1
    }
  })
  const reset = () => {
    localStorage.removeItem("slug")
    localStorage.setItem("spaceType", "meeting-spaces")
    localStorage.setItem("resetLocation", true)
    if (window.history) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname

      window.history.pushState({ path: newurl }, "", newurl)
      window.location.reload(true)
    }
  }
  const listsnew =
    localityFilter === "All"
      ? operatorName === "" || operatorName === null
        ? lists
        : lists.filter(list => list.node.operatorName === operatorName)
      : lists.filter(list => list.node.spaceAddress === localityFilter)

  return (
    <div>
      <SEOHeader
        title={
          "Business Meeting Rooms in " +
          city +
          " | Meeting Spaces in near " +
          city +
          " | GoFloaters"
        }
        description={
          "On-demand meeting rooms in " +
          city +
          " just a click away. Discover and book business meeting rooms, boardrooms, and conference spaces that help being hybrid, productive and great for collaborations."
        }
        socialURL={
          "https://assets.gofloaters.com/socialimage/meeting-rooms-in-" +
          cityslug +
          ".jpg"
        }
        pinterest="true"
      ></SEOHeader>
      <Layout page="city" type="meetingSpace">
        <div
          style={{
            position: "fixed",
            bottom: mobileFilterSecondary ? "0px" : "-100px",
            zIndex: "10",
            width: "100%",
            // opacity: mobileFilterSecondary ? "1" : "0",
            display: mobileFilterSecondary ? "block" : "none",
            background: "#fdfdfd",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
            boxShadow: "0px -16px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{ padding: "5px 15px", borderBottom: "1px solid #d6d6d6" }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sort</p>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "User Rating"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("User Rating")
              }}
            >
              <p>
                <b>User Rating:</b> Highest first
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "Featured"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("Featured")
              }}
            >
              <p>
                <b>Featured:</b> Highly Recommended
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Popular")
              }}
            >
              <p>
                <b>Popular:</b> Frequently booked
              </p>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div
              className={
                filterStyle === "Nearest" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Nearest")
              }}
            >
              <p>
                <b>Nearest:</b> Closest to you
              </p>
            </div>
          </div> */}

          <div className="col-md-12">
            <div
              className={
                filterStyle === "PriceLow"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceLow")
              }}
            >
              <p>
                <b>Price:</b> Lowest first
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "PriceHigh"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceHigh")
              }}
            >
              <p>
                <b>Price:</b> Highest first
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            top: "0",
            right: mobileFilter ? "0px" : "-200px",

            zIndex: "10",
            width: mobileFilter ? "100%" : "0px",
            // opacity: mobileFilter ? "1" : "0",
            height: "100vh",
            background: "#f8f8f8",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0",
              width: "100%",
              padding: "15px",
              background: "#ffffff",
              zIndex: "12",
            }}
          >
            <span
              onClick={() => {
                setmobileFilter(!mobileFilter)
              }}
              style={{
                textAlign: "center",
                float: "right",
                background: "#303030",
                color: "#fff",
                width: "30px",
                height: "30px",
                alignItems: "center",
                borderRadius: "50%",
                fontSize: "19px",
                top: "30px",
              }}
            >
              X
            </span>{" "}
            Filter
            {count - spaceSize <= 1 ? (
              ""
            ) : (
              <a
                style={{
                  color: "#4f9fc8",
                  padding: "2px 5px",
                  float: "right",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (window.history) {
                    var newurl =
                      window.location.protocol +
                      "//" +
                      window.location.host +
                      window.location.pathname

                    window.history.pushState({ path: newurl }, "", newurl)
                    window.location.reload(true)
                  }
                }}
              >
                CLEAR
              </a>
            )}
          </div>
          <br />

          <br />

          <div style={{ padding: "20px" }}>
            <MeetingSpaceFilter />
          </div>
          <br />

          <div
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              padding: "15px",
              background: "#ffffff !important",
              zIndex: "11",
            }}
          >
            <div
              onClick={() => {
                setmobileFilter(!mobileFilter)
              }}
              style={{
                background: "#2798B5",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "default",
                width: "100%",
                textAlign: "center",
              }}
            >
              Apply Filter
            </div>
          </div>

          <br></br>
          <br></br>
        </div>
        <div className="container-fluid">
          <p
            onClick={() => {
              setfiltersmodalView(true)
            }}
            className="floatingHelp"
          >
            <i className="fa " aria-hidden="true">
              💁
            </i>
            <span>Help me to find the right space!</span>
          </p>
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-2 DesktopOnly">
              <MeetingSpaceFilter />
            </div>
            <div className="col-md-10 noPadding">
              <div className="container noPadding">
                <div className={"floatingMobileFilters " + scrollState}>
                  <h1 className="meetingRoomHeading">
                    {operatorName && localityFilter === "All"
                      ? operatorName
                      : " "}{" "}
                    Meeting Rooms in {city}{" "}
                    <a className="changeCity MobileOnly" onClick={reset}>
                      <i
                        className="fa fa-pencil"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </a>
                    <a className="changeCity DesktopOnly" onClick={reset}>
                      Change City
                    </a>
                  </h1>
                  <p style={{ fontSize: "13px" }} className="DesktopOnly">
                    If you are looking for meeting spaces or conference rooms in{" "}
                    {city} then you have come to the right page. We have meeting
                    spaces in {city} starting from Rs 250 / hr.{" "}
                  </p>
                  <div className="row ">
                    {/* <div className="col-md-1 col-1 text-center noPaddingM">
                    <a
                      onClick={() => {
                        document.getElementById(
                          "popularscrollmenu"
                        ).scrollLeft -= 100
                      }}
                      className="arrowButton"
                    >
                      <i className="fa  fa-chevron-left"></i>
                    </a>
                  </div> */}
                    {/* <div className="col-md-12 col-12">
                    <div className="popularscrollmenu">
                      <Link
                        to={"/meeting-spaces/" + cityslug + "/all"}
                        className="popularlocalityLink active"
                      >
                        All
                      </Link>
                      {LocalityList.map(local => {
                        const localitynew = listscount.filter(
                          list =>
                            list.node.spaceAddress === local.node.localityName
                        )
                        if (localitynew.length > 0) {
                          return (
                            <Link
                              to={
                                "/meeting-spaces/" +
                                cityslug +
                                "/" +
                                local.node.localitySlug
                              }
                              className={
                                local.node.localityName === locality
                                  ? "popularlocalityLink active centerAligner "
                                  : "popularlocalityLink centerAligner"
                              }
                            >
                              {local.node.localityName}
                              {"  "}
                              <div className="countSlip centerAligner">
                                {localitynew.length}
                              </div>
                            </Link>
                          )
                        }
                      })}
                    </div>
                  </div> */}
                    {/* <div className="col-md-1 col-1 text-center noPaddingM">
                    <a
                      onClick={() => {
                        document.getElementById(
                          "popularscrollmenu"
                        ).scrollLeft += 100
                      }}
                      className="arrowButton"
                    >
                      <i className="fa  fa-chevron-right"></i>
                    </a>
                  </div> */}
                  </div>

                  <div
                    className="row DesktopOnly"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div className="col-md-12">
                      <OfficeFilter></OfficeFilter>
                    </div>
                  </div>
                  {/* <div className="DesktopOnly">
                  <div className="row alignersorting sortingCardList">
                    <div className="col-md-12">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#2193b0",
                        }}
                      >
                        SORT BY:
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "User Rating"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("User Rating")
                        }}
                      >
                        <p>User Rating</p>
                        <span>Highest first</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Featured"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Featured")
                        }}
                      >
                        <p>Featured</p>
                        <span>Highly Recommended</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Popular"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Popular")
                        }}
                      >
                        <p>Popular</p>
                        <span>Frequently booked</span>
                      </div>
                    </div>
                    
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Nearest"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Nearest")
                        }}
                      >
                        <p>Nearest</p>
                        <span>Closest to you</span>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "PriceLow"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("PriceLow")
                        }}
                      >
                        <p>Price</p>
                        <span>Lowest first</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "PriceHigh"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("PriceHigh")
                        }}
                      >
                        <p>Price</p>
                        <span>Highest first</span>
                      </div>
                    </div>
                  </div>
                </div> */}

                  <div className="row alignersorting">
                    <div className="MobileOnly col-6 noPadding">
                      <span
                        onClick={() => {
                          setfiltersmodalView(true)
                        }}
                        className="sortNowButton"
                      >
                        <i
                          className="fa fa-filter"
                          style={{ paddingRight: "10px" }}
                        ></i>{" "}
                        Filter
                      </span>
                    </div>{" "}
                    <div className="MobileOnly col-6 text-right noPadding">
                      <span
                        onClick={() => {
                          setmobileFilterSecondary(!mobileFilterSecondary)
                        }}
                        className="sortNowButton"
                      >
                        <i
                          className="fa fa-sort-amount-asc"
                          style={{ paddingRight: "10px", cursor: "pointer" }}
                        ></i>{" "}
                        Sort
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div
                    style={{
                      background: "#77dd77",
                      color: "#000",
                      fontSize: "1rem",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    Use code <b>FIRST10</b> to save 10%
                  </div>
                  <p style={{ padding: "15px 0 0", color: "#7a7a7a" }}>
                    {/* {seatCount} {optionValue} {miniValue} {maxiValue}
                    <br /> */}
                    {spaceSize} matching spaces{" "}
                  </p>
                </div>
                <div className="row">
                  {listsnew.map((list, i) => {
                    const listData = list.node
                    const isInstantBooking = instantBooking
                      ? listData.meetingInstantBooking
                      : true
                    let count = 0
                    if (
                      isInstantBooking &&
                      listData.Facility.search(
                        PowerBackupCheck ? "Power Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        parkingCheck ? "Free Parking" : " "
                      ) > 1 &&
                      (listData.Facility.search(
                        projectorCheck ? "Projector" : " "
                      ) > 1 ||
                        listData.Facility.search(
                          projectorCheck ? "Television" : " "
                        ) > 1) &&
                      listData.Facility.search(
                        whiteboardCheck ? "Whiteboard" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        cafeteriaCheck ? "Pantry" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        powerBackup ? "Power Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        WiFiBackupCheck ? "WiFi Backup" : " "
                      ) > 1 &&
                      listData.Facility.search(CoffeeTea ? "Coffee/Tea" : " ") >
                        1 &&
                      listData.Facility.search(
                        MetroNearbyCheck ? "Metro Nearby" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        OnetoOneMeetings ? "One to One Meetings" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        TeamMeetings ? "Team Meetings" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        BoardMeetingsPresentations
                          ? "Board Meetings & Presentations"
                          : " "
                      ) > 1 &&
                      listData.Facility.search(budgetFilter ? "Budget" : " ") >
                        1 &&
                      listData.Facility.search(
                        businessFilter ? "Business" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        premiumFilter ? "Premium" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        Interviews ? "Interviews" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        WorkshopsEvents ? "Workshops & Events" : " "
                      ) > 1 &&
                      listData.Facility.search(Training ? "Training" : " ") >
                        1 &&
                      listData.Facility.search(operationFilter) > 1 &&
                      listData.Facility.search(
                        saturdayOperation ? "Saturday Operation" : " "
                      ) > 1 &&
                      listData.Facility.search(
                        sundayOperation ? "Sunday Operation" : " "
                      ) > 1 &&
                      listData.Facility.search(Training ? "Training" : " ") >
                        1 &&
                      Number(listData.priceHr) >= Number(miniValue) &&
                      Number(listData.priceHr) <= Number(maxiValue) &&
                      (seatCount > 0
                        ? seatCount >= 20
                          ? Number(listData.seat) >= 20
                          : Number(listData.seat) === Number(seatCount)
                        : Number(listData.seat) >= 0)
                    ) {
                      return (
                        <MeetingListingCard
                          key={listData.spaceId}
                          spacetype={listData.spaceType}
                          listingImg={listData.photos[0]}
                          title={listData.spaceTitle}
                          gftitle={listData.spaceGFName}
                          spaceDesc={listData.spaceDesc}
                          spaceAddress={listData.spaceAddress}
                          priceHr={listData.priceHr}
                          priceDay={listData.priceDay}
                          priceMonth={listData.priceMonth}
                          monthPassAvailable={listData.monthPassAvailable}
                          dayPassAvailable={listData.dayPassAvailable}
                          hourPassAvailable={listData.hourPassAvailable}
                          spaceId={listData.spaceId}
                          officeSpaceType={listData.officeSpaceType}
                          spaceDisplayName={listData.spaceDisplayName}
                          OriginalName={listData.OriginalName}
                          Facility={listData.Facility}
                          Slug={"/meeting-space/" + listData.slug}
                          hasCovidSafeBadge={listData.hasCovidSafeBadge}
                          online={listData.online}
                          Rating={Number(listData.avgRating).toFixed(2)}
                          priceperdayPreDiscount={
                            listData.priceperdayPreDiscount
                          }
                          type="Meeting"
                          priceperhrPreDiscount={listData.priceperhrPreDiscount}
                          geoLocation={listData.geoLocation}
                          purposesList={listData.purposesList}
                          bookingcount={listData.bookingcount}
                          googleReviewCount={listData.googleReviewCount}
                          spaceFeedback={listData.spaceFeedback}
                          googleMapsLink={listData.googleMapsLink}
                          meetingInstantBooking={listData.meetingInstantBooking}
                          meetingRoomName={listData.meetingRoomName}
                        ></MeetingListingCard>
                      )
                    }
                  })}
                </div>
                <br></br>
                <MeetingContent city={city} locality={locality} />
              </div>{" "}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>{" "}
        <div className="container">
          <div className="row">
            <BlogCardCategory filter={filter} />
          </div>
        </div>
        <br></br>
        <br></br>{" "}
        {filtersmodalView ? (
          <div
            className="meetingPage modal fade show"
            id="selectCity"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="selectCity"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog " role="document">
              <div className="modal-content" style={{ background: "#fff" }}>
                <div className="modal-body" style={{ padding: 15 }}>
                  <div className="col-md-12 col-12">
                    <div className="row aligner">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setfiltersmodalView(false)
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <span className="modalTitle  ">
                          Let us help you find the best meeting space
                        </span>
                        {/* <span className="modalTitle MobileOnly">
                          {spaceSize} best meeting space in {city}
                        </span> */}
                      </div>
                      <div className="col-md-8 borderRight">
                        <p>Locality</p>
                        <p className="selectCSS ">
                          <select
                            onChange={localityChange}
                            value={localityFilter}
                          >
                            <option value="All">
                              All Localities ({count} Options)
                            </option>
                            {LocalityListSelect}
                          </select>
                        </p>
                        <p>What is the purpose of the meeting</p>
                        <div className="modalCheck">
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <label className="radio">
                                <input
                                  type="checkbox"
                                  id="AnySpaces"
                                  name="AnySpaces"
                                  value="TeamMeetings"
                                  checked={
                                    purposeFilter === "AnySpaces" ? true : false
                                  }
                                  onChange={() => {
                                    setAnySpaces(!AnySpaces)
                                    setTeamMeetings(false)
                                    setTraining(false)
                                    setBoardMeetingsPresentations(false)
                                    setInterviews(false)
                                    purposeChange("AnySpaces")
                                  }}
                                />
                                <span>Any</span>
                              </label>
                            </li>
                            <li>
                              <label className="radio">
                                <input
                                  type="checkbox"
                                  id="TeamMeetings"
                                  name="TeamMeetings"
                                  value="TeamMeetings"
                                  checked={
                                    purposeFilter === "Team Meeting"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setTeamMeetings(!TeamMeetings)
                                    setTraining(false)
                                    setBoardMeetingsPresentations(false)
                                    setInterviews(false)
                                    purposeChange("Team Meeting")
                                    setAnySpaces(false)
                                  }}
                                />
                                <span>Team Meetings</span>
                              </label>
                            </li>
                            <li>
                              <label className="radio">
                                <input
                                  type="checkbox"
                                  id="Training"
                                  name="Training"
                                  value="Training"
                                  checked={
                                    purposeFilter === "Training" ? true : false
                                  }
                                  onChange={() => {
                                    setTeamMeetings(false)
                                    setTraining(!Training)
                                    setBoardMeetingsPresentations(false)
                                    setInterviews(false)
                                    purposeChange("Training")
                                    setAnySpaces(false)
                                  }}
                                />
                                <span>Training</span>
                              </label>
                            </li>
                            <li>
                              <label className="radio">
                                <input
                                  type="checkbox"
                                  id="BoardMeetingsPresentations"
                                  name="BoardMeetingsPresentations"
                                  value="BoardMeetingsPresentations"
                                  checked={
                                    purposeFilter === "Board Meetings"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setTeamMeetings(false)
                                    setTraining(false)
                                    setBoardMeetingsPresentations(
                                      !BoardMeetingsPresentations
                                    )
                                    setInterviews(false)
                                    purposeChange("Board Meetings")
                                    setAnySpaces(false)
                                  }}
                                />
                                <span>Board Meetings</span>
                              </label>
                            </li>
                            <li>
                              <label className="radio">
                                <input
                                  type="checkbox"
                                  id="Interviews"
                                  name="Interviews"
                                  value="Interviews"
                                  checked={
                                    purposeFilter === "Interviews"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setTeamMeetings(false)
                                    setTraining(false)
                                    setBoardMeetingsPresentations(false)
                                    setInterviews(!Interviews)
                                    purposeChange("Interviews")
                                    setAnySpaces(false)
                                  }}
                                />
                                <span>Interviews</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div style={{ padding: "5px 0" }}>
                          <p>For how many peoples</p>
                          <div className="selectCSS">
                            <select onChange={seatChange} value={seatCount}>
                              <option value={0}>Select</option>
                              <option value={4}>4 people</option>
                              <option value={6}>6 people</option>
                              <option value={8}>8 people</option>
                              <option value={10}>10 people</option>
                              <option value={12}>12 people</option>
                              <option value={15}>15 people</option>
                              <option value={20}>&gt; 20 people</option>
                            </select>
                          </div>
                        </div>

                        <div
                          style={{ padding: "5px 0" }}
                          className="modalCheck"
                        >
                          <p>Budget</p>
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <label
                                className={
                                  optionValue === "option_0"
                                    ? "radio active"
                                    : "radio "
                                }
                              >
                                <input
                                  type="radio"
                                  name="priceFilter"
                                  checked={optionValue === "option_0"}
                                  onChange={value => {
                                    setoptionValue("option_0")
                                    setminiValue(0)
                                    setmaxiValue(20000)
                                    priceFilterChange("option_0", 0, 20000)
                                  }}
                                ></input>
                                <span>Any </span>
                              </label>
                            </li>
                            <li>
                              <label
                                className={
                                  optionValue === "option_1"
                                    ? "radio active"
                                    : "radio"
                                }
                              >
                                <input
                                  type="radio"
                                  name="priceFilter"
                                  checked={optionValue === "option_1"}
                                  onChange={value => {
                                    setoptionValue("option_1")
                                    setminiValue(0)
                                    setmaxiValue(
                                      seatCount != 0 ? seatCount * 125 : 500
                                    )
                                    priceFilterChange(
                                      "option_1",
                                      0,
                                      seatCount != 0 ? seatCount * 125 : 500
                                    )
                                  }}
                                ></input>
                                <span>
                                  Less than ₹{" "}
                                  {seatCount != 0 ? seatCount * 125 : 500}{" "}
                                </span>
                              </label>
                            </li>
                            <li>
                              <label
                                className={
                                  optionValue === "option_2"
                                    ? "radio active"
                                    : "radio"
                                }
                              >
                                <input
                                  type="radio"
                                  name="priceFilter"
                                  checked={optionValue === "option_2"}
                                  onChange={value => {
                                    setoptionValue("option_2")
                                    setminiValue(
                                      seatCount != 0 ? seatCount * 125 : 500
                                    )
                                    setmaxiValue(
                                      seatCount != 0 ? seatCount * 150 : 600
                                    )
                                    priceFilterChange(
                                      "option_2",
                                      seatCount != 0 ? seatCount * 125 : 500,
                                      seatCount != 0 ? seatCount * 150 : 600
                                    )
                                  }}
                                ></input>
                                <span>
                                  ₹ {seatCount != 0 ? seatCount * 125 : 500} to
                                  ₹ {seatCount != 0 ? seatCount * 150 : 600}
                                </span>
                              </label>
                            </li>
                            <li>
                              <label
                                className={
                                  optionValue === "option_3"
                                    ? "radio active"
                                    : "radio"
                                }
                              >
                                <input
                                  type="radio"
                                  name="priceFilter"
                                  checked={optionValue === "option_3"}
                                  onChange={value => {
                                    setoptionValue("option_3")
                                    setminiValue(
                                      seatCount != 0 ? seatCount * 150 : 600
                                    )
                                    setmaxiValue(
                                      seatCount != 0 ? seatCount * 200 : 800
                                    )
                                    priceFilterChange(
                                      "option_3",
                                      seatCount != 0 ? seatCount * 150 : 600,
                                      seatCount != 0 ? seatCount * 200 : 800
                                    )
                                  }}
                                ></input>
                                <span>
                                  ₹ {seatCount != 0 ? seatCount * 150 : 600} to
                                  ₹ {seatCount != 0 ? seatCount * 200 : 800}
                                </span>
                              </label>
                            </li>
                            <li>
                              <label
                                className={
                                  optionValue === "option_4"
                                    ? "radio active"
                                    : "radio"
                                }
                              >
                                <input
                                  type="radio"
                                  name="priceFilter"
                                  checked={optionValue === "option_4"}
                                  onChange={value => {
                                    setoptionValue("option_4")
                                    setminiValue(
                                      seatCount != 0 ? seatCount * 200 : 800
                                    )
                                    setmaxiValue(20000)
                                    priceFilterChange(
                                      "option_4",
                                      seatCount != 0 ? seatCount * 200 : 800,
                                      20000
                                    )
                                  }}
                                ></input>
                                <span>
                                  {" "}
                                  Over ₹{" "}
                                  {seatCount != 0 ? seatCount * 200 : 800}{" "}
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{ padding: "5px 0" }}
                          className="modalCheck"
                        >
                          <p>Quick Filter</p>
                          <div className="quickFilter">
                            <li>
                              <a
                                className={"CheckBox " + instantBooking}
                                onClick={InstantBookingCheck}
                              >
                                Instantly Book{" "}
                                <i
                                  className="fa fa-times-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 text-center ">
                        <div className="DesktopOnly">
                          <p style={{ fontSize: "18px" }}>
                            {count} total space in
                            <br />{" "}
                            <span
                              style={{ fontSize: "22px", fontWeight: "bold" }}
                            >
                              {" "}
                              {city}
                            </span>
                          </p>
                          <br />
                          <div
                            style={{
                              border: "1px solid #303030",
                              borderRadius: "10px",
                              padding: "20px 0",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "36px",
                              }}
                            >
                              {spaceSize}
                            </span>
                            <br />
                            Matching Spaces
                          </div>
                        </div>
                        <br /> <br />
                        <div className="MobileOnly">
                          <br />
                          <br />
                        </div>
                        <div className="DesktopOnly">
                          <button
                            style={{
                              color: "#fff",
                              background: "#2193b0",
                              padding: "15px 25px",
                              borderRadius: "10px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              border: "none",
                            }}
                            onClick={() => {
                              setfiltersmodalView(false)
                            }}
                          >
                            See Matches
                          </button>
                        </div>
                        <div className="MobileOnly">
                          <div
                            style={{
                              position: "fixed",
                              bottom: "0",
                              width: "100%",
                              padding: "15px",
                              background: "#ffffff !important",
                              zIndex: "11",
                              left: "0",
                            }}
                          >
                            <div
                              style={{
                                background: "#2798B5",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                cursor: "default",
                                width: "100%",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                setfiltersmodalView(false)
                              }}
                            >
                              See {spaceSize} Matches
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div style={{ paddingTop: "100px" }} className="MobileOnly"></div>
      </Layout>
    </div>
  )
}
export default MeetingSpacesLocalityAll
export const query = graphql`
  query MeetingSpacesLocalityAllCR($city: String!) {
    city: allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        spaceCity: { eq: $city }
        online: { eq: true }
      }
      sort: { fields: spaceRating, order: DESC }
    ) {
      totalCount
      edges {
        node {
          OriginalName
          spaceAddress
          dayPassAvailable
        }
      }
    }
    allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        spaceCity: { eq: $city }
        slug: { ne: "" }
        online: { eq: true }
      }
      sort: { fields: spaceRating, order: DESC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceId
          spaceImage
          photos
          spaceTitle
          spaceDesc
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          pincode
          bookingcount
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
          operatorName
          googleMapsLink
          googleRating
          googleReviewCount
          avgRating
          geoLocation
          seat
          spaceRating
          meetingInstantBooking
          meetingRoomName
          spaceFeedback {
            feedback
            rating
          }
        }
      }
    }
    counter: allListings(
      filter: {
        spaceType: { eq: "Conference Room" }
        online: { eq: true }
        spaceCity: { eq: $city }
      }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceDesc
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          seat
          online
          pincode
          isFeatured
          priceperdayPreDiscount
          priceperhrPreDiscount
          operatorName
          spaceRating
          meetingInstantBooking
          meetingRoomName
        }
      }
    }
    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
  }
`
